import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-not-found" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["innerHTML"]

import { appendCampaignQueryParams } from '@src/util/append_campaign_query_params';
import { inject } from 'vue';
import { QUERY_STRING_INJECTION_KEY } from '@src/util/createCampaignQueryString';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageNotFound',
  setup(__props) {


const campaignParams = inject<string>( QUERY_STRING_INJECTION_KEY, '' );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t( 'page_not_found_header' )), 1),
    _createElementVNode("span", {
      innerHTML: _unref(appendCampaignQueryParams)( _ctx.$t( 'page_not_found' ), _unref(campaignParams) )
    }, null, 8, _hoisted_3)
  ]))
}
}

})